<template>
  <div class="basic-info ">
    <div class="box-header m-0 p-4 d-flex align-items-center justify-content-between">
     <div class="col-sm-6 p-0">
      <h6 class="fs-20 mb-0">User List</h6>
     </div>
     <div class="col-sm-3 p-0">
      <div class="search-col m-0">
        <input type="text" class="form-control float-right" placeholder="Enter Search" @input="filterData($event)" />
      </div>
     </div>
    </div>

  <ag-grid-vue
    class="ag-theme-alpine w-100" :style="{height: (windowHeight-250) +'px' }"
    :columnDefs="columnDefs"
    :rowData="rowData"
    :defaultColDef="defaultColDef"
    rowSelection="multiple"
    animateRows="true"
    :suppressDragLeaveHidesColumns="true"
    :pagination="true"
    :paginationAutoPageSize="true"
    cacheBlockSize="100"
    :singleClickEdit="true"
    @cell-clicked="cellWasClicked"
    @grid-ready="onGridReady"
    @rowClicked="onRowClicked"
  >
  </ag-grid-vue>
  </div>
</template>
<script>
// @ is an alias to /src
import axios from "axios";
import { AgGridVue } from "ag-grid-vue3"; // the AG Grid Vue Component
import { reactive, onMounted, ref } from "vue";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import StatusDot from '../StatusDot.vue';

export default {
  props: {
    id: Number,
  },
  name: "UserTable",
  components: {
    AgGridVue,
    StatusDot: StatusDot,
  },

  data() {
    return {
      windowHeight: 700,
      columnDefs: null,
      rowData: null,
      defaultColDef: { resizable: true, minWidth: 120, flex: 1, pinned: false, sortable: true },
      gridApi: null,
      columnApi: null,
    }
  },




  setup() {
    // const gridApi = ref(null); // Optional - for accessing Grid's API

    // // Obtain API from grid's onGridReady event
    // const onGridReady = (params) => {
    //   gridApi.value = params.api;
    // };

    // const rowData = reactive({}); // Set rowData to Array of Objects, one Object per Row

    // Each Column Definition results in one Column.
    // const columnDefs = reactive({
    //   value: [
    //     { headerName: "#", valueGetter: "node.rowIndex + 1"},
    //     { headerName: "User Name", field: "first_name" },
    //     { headerName: "Vendor", field: "vendorname" },
    //     { field: "email" },
    //     { headerName: "Phone",field: "mobile"},
    //     { headerName: "Phone",field: "mobile"},
    //     { headerName: "Status",field: "status",cellRenderer: 'StatusDot'}
    //     ],
    // });

    // DefaultColDef sets props common to all Columns
    // const defaultColDef = {
    //   sortable: true,
    //   filter: true,
    //   flex: 1,
    //   floatingFilter: true,
    // };

    return {
      // onGridReady,
      // columnDefs,
      // rowData,
      // defaultColDef,
      cellWasClicked: (event) => {
        // console.log("cell was clicked", event);
      },
      deselectRows: () => {
        // gridApi.value.deselectAll();
      },
    };
  },

  mounted: function () {
    this.getUserList();
  },

  methods: {
    onGridReady(params) {
     this.gridApi = params.api;
     this.gridColumnApi = params.columnApi;
    },

    filterData(e) {
      this.gridApi.setQuickFilter(e.target.value);
    },

    getUserList() {
      let self = this;
      self.$loading = true;
      axios
        .get("api/v1/vendor/get-alluser/"+self.id)
        .then(function (response) {
          self.rowData = response.data.data;
        })
        .catch(function (error) {
          self.$loading = false;
        });
    },
    onRowClicked(event) {
      console.log(event.data.id);
      let id = event.data.id;
      this.$router.push({ name: 'UserSummary', params: { id: id }});
    },
  },
  beforeMount() {
    this.columnDefs = [
        { headerName: "#", valueGetter: "node.rowIndex + 1", width:20, maxWidth:20},
        { headerName: "User Name", field: "first_name", width:150, maxWidth:150},
        { headerName: "Vendor", field: "vendorname", width:150, maxWidth:150},
        { field: "email", width:300, maxWidth:300},
        { headerName: "Phone",field: "mobile", width:120, maxWidth:120},
        { headerName: "Status",field: "status",cellRenderer: 'StatusDot', width:120, maxWidth:120}
    ];
  },
};
</script>
