<template>

  <div class="modal" id="myModal">
    <div class="modal-lg modal-dialog">
      <div class="modal-content">
    
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Changes To Enterprise Information</h4>
          <button type="button" class="close" data-dismiss="modal" @click="close()">&times;</button>
        </div>
        
        <!-- Modal body -->
        <div class="modal-body">
          <div v-for="(value, name, index) in loddiff">
            
            <!-- <h4 class="modal-title" v-if="name != ''">{{name}}</h4> -->
            <!-- <div v-if="value.length > 0"> -->
          <div class="row">

          <div class="col-md-6" v-for="(v_value, v_name, v_index) in value">
            <!-- <div class="mb-4 row"> -->
              <div v-if="v_name != 'updated_at' && v_name != 'deleted_date'">
                <div class="text-start col-md-6 mb-4" v-if="!v_name.includes('_old')">
                  <label class="grey-60 fs-12 mb-1 text-design"> {{modifiedKey(v_name)}}</label>

                  <p class="m-0 fs-14 text-black text-design" v-if="value[v_name] == null" style="color: #C6C6C6">None</p>
                  <p class="m-0 fs-14 text-black text-design" v-else>{{value[v_name]}}</p>

                  <p class="m-0 fs-14 grey-60 text-design" v-if="value[v_name+'_old'] == null" style="color: #C6C6C6">
                    None</p>
                  <p class="m-0 fs-14 grey-60 text-design" 
                  style="text-decoration: line-through;" v-else>
                    {{value[v_name+'_old']}}</p>
                </div>
              </div>
            </div>  
          </div> 
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="basic-info ">

    <div class="box-header m-0 p-4 d-flex align-items-center justify-content-between">
     <div class="col-sm-6 p-0">
      <h6 class="fs-20 mb-0">Activity Log</h6>
     </div>
     <div class="col-sm-3 p-0">
      <div class="search-col m-0">
        <input type="text" class="form-control float-right" placeholder="Enter Search" @input="filterData($event)" />
      </div>
     </div>
    </div>
      <b-card body-class="p-0">
      <ag-grid-vue
        class="ag-theme-alpine w-100" :style="{height: (windowHeight-250) +'px' }"
        :columnDefs="columnDefs"
        :rowData="rowData"
        :defaultColDef="defaultColDef"
        rowSelection="multiple"
        animateRows="true"
        :suppressDragLeaveHidesColumns="true"
        :pagination="true"
        :paginationAutoPageSize="true"
        cacheBlockSize="100"
        :singleClickEdit="true"
        @cell-clicked="cellWasClicked"
        @grid-ready="onGridReady"
        @rowClicked="onRowClicked"
      >
      </ag-grid-vue>
    </b-card>
  </div>


</template>
<script>
// @ is an alias to /src
import axios from "axios";
import { AgGridVue } from "ag-grid-vue3"; // the AG Grid Vue Component
import { reactive, onMounted, ref } from "vue";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
export default {
  props: {
    id: Number,
  },
  name: "ActivityLog",
  components: {
    AgGridVue,
  },
  data() {
    return {
      windowHeight: 700,
      columnDefs: null,
      rowData: null,
      defaultColDef: { resizable: true, minWidth: 120, flex: 1, pinned: false, sortable: true },
      gridApi: null,
      columnApi: null,
      loddiff:'',
    }
  },

  mounted: function () {
    this.getUserList();
  },

  methods: {
    
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    filterData(e) {
      this.gridApi.setQuickFilter(e.target.value);
    },
    
    close(){
      $('#myModal').toggle();
    },
    getUserList() {
      let self = this;
      self.$loading = true;
      let v_id = this.$route.params.id;
      axios
        .get("api/v1/user-log/listing/"+v_id)
        //.get("api/v1/user-log/listing")
        .then(function (response) {
          self.rowData = response.data.data;
        })
        .catch(function (error) {
          self.$loading = false;
        });
    },
    modifiedKey(str) {
      if(str!='' && str!=null){
        return str.replace(/_/g, ' ');
      }
      return str;
    },
    cellWasClicked(event) {
      if(event.column.colId == '_id'){
      console.log(event.column.colId);
      $('#myModal').toggle();
      this.getLogDetail(event.data._id);
      }
    },

    getLogDetail(id) {
      let self = this;
      axios
        .get("api/v1/user-log/logdata/"+id)
        .then(function (response) {
           let singleLogdata = response.data;
           self.loddiff = singleLogdata.logdetail;
        })
        .catch(function (error) {

        });
    },

  },
  beforeMount() {
    this.columnDefs = [
        { headerName: "#", valueGetter: "node.rowIndex + 1", width:50, maxWidth:50},
        { headerName: "VendorId", field: "v_id", width:100, maxWidth:100},
        { headerName: "Vendor Name", field: "name", width:200, maxWidth:200},
        {
          headerName: 'CreatedAt',
          field: 'created_at', width:250, maxWidth:250,
          valueFormatter: function (params) {
            // Format the date using your desired format
            const formattedDate = new Date(params.value).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
            });

            return formattedDate;
          },
        },
        {
          headerName: 'UpdatedAt',
          field: 'updated_at', width:250, maxWidth:250,
          valueFormatter: function (params) {
            // Format the date using your desired format
            const formattedDate = new Date(params.value).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
            });

            return formattedDate;
          },
        },

        { 
          headerName: 'Action', 
          field: '_id', width:150, maxWidth:150,
          cellRenderer: (params) => {
            const link = document.createElement('a');
            link.style.textDecoration="none";
            link.href = '#';
            link.rel = 'noopener noreferrer';
            link.textContent = 'View detail';
            return link;
          }
        }
      ];
  },
};
</script>

<style scoped>

.text-design {
  text-transform: capitalize;
  font-weight: 800;
}

</style>
