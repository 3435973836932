<template>
<v-container fluid class="App">
    <SideMenu/>
    <router-view />
    <!-- <img class="loader-box" src="../src/assets/img/loader/Loader_720px.gif" alt="loader"> -->
</v-container>
</template>
<script>
// @ is an alias to /src
import SideMenu from "@/components/SideMenu.vue";
import { mapGetters} from 'vuex'
 

export default {
  name: "App",
  components: {
    SideMenu
  },
  computed:{
    ...mapGetters(['isLoggedIn', 'user'])
  },
  mounted() {
    console.log("asdf"+this.isLoggedIn);
    if (!this.isLoggedIn) {
        this.$router.push("/");
    } 
  },

};
</script>

