<template>
  <div class="bgwhite">
    <div class="p-0 container-fluid">
      <div class="box-header">
        <div class="col-md-12 pl-0 pr-0">
          <div class="d-flex align-items-center">
            <div class="col-md-6 pr-0">
              <h4 class="box-title m-0 fw-5">Enterprises</h4>
            </div>
            <div class="text-end col-md-6 pr-0">
            <router-link to="/add-organization" style="text-decoration: none; color: #575757">
                <button type="button" class="custom-primary btn btn-primary">
                  New Enterprise
                </button></router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="card-org">
        <div class="sc-dnwKUv gNJUsR" style="display: none">
          <div class="sc-fLQRDB jPphKt">
            <div class="sc-beqWaB fRrkHJ rdt_Table" role="table">
              <div class="sc-ivnCJf gUHoGv">
                <div style="padding: 24px">There are no records to display</div>
              </div>
            </div>
          </div>
        </div>

        <b-card  body-class="p-0 ag-grid-vue-custom">

          <div class="d-flex justify-content-between align-items-center">
            <div class="col-sm-4 m-3 p-0">
              <h6 class="fs-16 mb-0">All Enterprises</h6>
            </div>
 
          <div class="col-sm-3 m-3 p-0">
            <div class="search-col ">
              <input type="text" class="form-control " placeholder="Enter Search" @input="filterData($event)" />
            </div>
          </div>
         </div>

        <ag-grid-vue
          class="ag-theme-alpine w-100" :style="{height: (windowHeight-250) +'px' }"
          :columnDefs="columnDefs"
          :rowData="rowData"
          :defaultColDef="defaultColDef"
          rowSelection="multiple"
          animateRows="true"
          :suppressDragLeaveHidesColumns="true"
          :pagination="true"
          :paginationAutoPageSize="true"
          cacheBlockSize="100"
          :singleClickEdit="true"
          @cell-clicked="cellWasClicked"
          @grid-ready="onGridReady"
          @rowClicked="onRowClicked"
        >
        </ag-grid-vue>
      </b-card>
      </div>
    </div>
  </div>
</template>
<script>

//import axios from "axios";
import axios from "../../axios";
import { AgGridVue } from "ag-grid-vue3"; // the AG Grid Vue Component
import { reactive, onMounted, ref } from "vue";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import StatusDot from '../StatusDot.vue';

export default {
  name: "OrganizationList",

  components: {
    AgGridVue,
    StatusDot: StatusDot,
  },

  data() {
    return {
      windowHeight: 700,
      // page: 1,
      columnDefs: null,
      rowData: null,
      defaultColDef: { resizable: true, minWidth: 120, flex: 1, pinned: false, sortable: true },
      gridApi: null,
      columnApi: null,
    }
  },

  mounted: function () {
    this.getVendirList();
  },

  methods: {
     onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
     },

    filterData(e) {
      this.gridApi.setQuickFilter(e.target.value);
    },
    getVendirList() {
      let self = this;
      let headers = { 'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('session')).token }; 

      self.$loading = true;
      axios
        .get("api/v1/vendor/all",{headers})
        .then(function (response) {
          self.rowData = response.data.data;
        })
        .catch(function (error) {
          self.$loading = false;
        });
    },
    onRowClicked(event) {
      let id = event.data.id;
      this.$router.push({ name: 'OrganizationView', params: { id: id }});
    },
  },
   beforeMount() {
    this.columnDefs = [
        { headerName: "#", valueGetter: "node.rowIndex + 1", width:30, maxWidth:30},
        { headerName: "Trade Name", field: "name"},
        { headerName: "Ref Enterprise Code", field: "ref_vendor_code"},
        { headerName: "Enterprise Id", field: "id"},
        { headerName: "BA", field: "client_id"},
        { headerName: "Organization Type", field: "category_type"},
        { field: "email"},
        { field: "phone"},
        { headerName: "Status",field: "status",cellRenderer: 'StatusDot', width:120, maxWidth:120}
    ];
  },
};
</script>
<style scoped>

</style>

