<template>
  <div class="bgwhite">
    <div class="p-0 container-fluid">
      <div class="box-header">
        <div class="col-md-12">
          <div class="d-flex align-items-center row">
            <div class="col-md-6">
              <a class="text-primary mb-4" href="/manage">← User</a>
              <h4 class="box-title m-0 fs-20 fw-5">New User</h4>
            </div>
            <div class="text-end col-md-6">

 
                <a @click="$router.go(-1)">

              <button type="button" class="text-button mr-2 btn btn-primary">
                  Discard
                </button>
              </a>
              <button type="button" class="custom-primary btn btn-primary" @click="saveUser" :disabled="btnDisabled">
                Save User
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-add-org">
        <div class="text-left col-md-12">
          <h4 class="fs-20 fw-5 text-start box-title">User Details</h4>
        </div>
        <div class="mb-5 col-md-12">
          <div class="d-flex align-items-center row">
            <div class="col-md-8">
              <div class="mb-3 row">
                <div class="text-start col-md-6">
                  <label class="form-label"
                    >First Name<span class="text-danger">*</span></label
                  >
                  <input
                    placeholder="Enter Organization Name"
                    autocomplete="off"
                    v-model="first_name"
                    name="first_name"
                    type="text"
                    class="form-control"
                  />
                  <span
                     class="help-block text-danger"
                     v-for="sn in errors.first_name"
                     v-html="sn"
                   ></span>
                </div>
                <div class="text-start col-md-6">
                  <label class="form-label"
                    >Last Name<span class="text-danger">*</span></label
                  >
                  <input
                    placeholder="Enter Last Name"
                    autocomplete="off"
                    v-model="last_name"
                    name="last_name"
                    type="text"
                    class="form-control"
                  />
                  <span
                     class="help-block text-danger"
                     v-for="sn in errors.last_name"
                     v-html="sn"
                   ></span>
                </div>
              </div>
              <div class="mb-3 row">
                <div class="text-start col-md-6">
                  <label class="form-label"
                    >Email <span class="text-danger">*</span></label
                  >
                  <input
                    placeholder="Enter email"
                    autocomplete="off"
                    v-model="email"
                    name="email"
                    type="email"
                    class="form-control"
                  />
                  <span
                     class="help-block text-danger"
                     v-for="sn in errors.email"
                     v-html="sn"
                   ></span>
                </div>
                <div class="text-start col-md-6">
                  <label class="form-label">Phone Number</label>
                  <input
                    maxlength="10"
                    placeholder="Enter Phone"
                    autocomplete="off"
                    name="mobile"
                    v-model="mobile"
                    type="text"
                    class="form-control"
                  />
                  <span
                     class="help-block text-danger"
                     v-for="sn in errors.mobile"
                     v-html="sn"
                   ></span>
                </div>
              </div>

               <div class="mb-3 row">
               <div class="text-start col-md-6">
                  <label class="form-label"
                    >Status<span class="text-danger">*</span></label
                  >
                  <select
                    aria-label="Default select example"
                    name="status"
                    class="form-select" 
                    v-model="status"
                  >

                   <option value="" disabled selected="true">Choose Status</option>
                   <option v-for="option in statusOptions" :key="option.value" :value="option.value">{{ option.name }}</option>
                  </select>
                  <span class="help-block text-danger" v-for="sn in errors.status" v-html="sn"></span>
                </div>
                 <div class="text-start col-md-6">
                  <label class="form-label">Vendor<span class="text-danger">*</span></label>
                  <select
                    aria-label="Default select example"
                    name="vendor"
                    class="form-select"
                    v-model="vendor"
                  >
                  <option value="" disabled selected="true">Choose Vendor</option>
                  <option v-for="option in vendorsList" :key="option.id" :value="option.id">{{ option.name }}</option>
                  </select>
                  <span class="help-block text-danger" v-for="sn in errors.vendor" v-html="sn"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="text-left col-md-12">
            <h4 class="fs-16 text-start mb-4">Password*</h4>
          </div>
          <div class="d-flex align-items-center row">
            <div class="col-md-8">
              <div class="mb-3 row">
                <div class="text-start col-md-6">
                  <label class="form-label"
                    >Password<span class="text-danger">*</span></label
                  >
                  <input
                    placeholder="Password"
                    autocomplete="off"
                    name="password"
                    v-model="password"
                    type="password"
                    class="form-control"
                  />
                <span class="help-block text-danger" v-for="sn in errors.password" v-html="sn"></span>
                </div>
                <div class="text-start col-md-6">
                  <label class="form-label"
                    >Confirm Password<span class="text-danger">*</span></label
                  >
                  <input
                    placeholder="Confirm Password"
                    autocomplete="off"
                    name="confirm_password"
                    v-model="confirm_password"
                    type="password"
                    class="form-control"
                  />
                  <span class="help-block text-danger" v-for="sn in errors.confirm_password" v-html="sn"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import axios from "axios";
import Multiselect from 'vue-multiselect';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
  name: "AddUser",
  components: {
    Multiselect,
  },

  data() {
    return {
      first_name: "",
      last_name: "",
      email: "",
      mobile: "",
      status: "",
      vendor: this.$route.params.id,
      emailVerified: false,
      password: "",
      confirm_password: "",
      vendors: [],
      statusOptions: [
      {name: 'Active', value: 1, },
      {name: 'InActive', value: 0, },
    ],
    vendorsList: [],
    errors:[],
    };
  },

  mounted: function () {
    this.getVendorList();
  },
  watch: {},
  methods: {
    getVendorList() {
      let self = this;
      self.$loading = true;
      axios
        .get("api/v1/vendor/list")
        .then(function (response) {
          self.vendorsList = response.data;
        })
        .catch(function (error) {
          self.$loading = false;
        });
    },
    saveUser() {
      var self = this;
      let data = {
        first_name: self.first_name,
        last_name: self.last_name,
        email: self.email,
        mobile: self.mobile,
        status: self.status,
        vendor: self.vendor,
        password: self.password,
        confirm_password: self.confirm_password,
      };
      axios.post("api/v1/vendor/createuser", data).then((response) => {
          if(response.data.status == 'success'){
            toast.success(response.data.message);
            let idd = self.$route.params.id;
            setTimeout(function () {
              self.$router.push({ path: '/view-organization/'+idd });
            },1000);             
          }
      }).catch(function(error) {
         toast.error("validation failled");
         self.errors=error.response.data.errors;
      });


    },
  },
   computed: {
      btnDisabled(){
       let check = false;
        if(this.first_name != '' && this.last_name !='' && this.email !='' && this.mobile !='' && this.vendor != '' && this.password != '' && this.confirm_password != '' && this.password == this.confirm_password){
            check = false;
        }
        return check;
      },
   },
};
</script>
<style>

@import url("../../assets/styles/themes/_default.scss");
</style>
