<template>
  <div class="form-floating">
    <input
      :class="{ 'form-control': true, 'is-invalid': error }"
      :type="type"
      :maxlength="inputlength"
      autocomplete="off"
      ref="value"
      :value="value"
      @input="$emit('input', $event.target.value)"
      :disabled="loading"
      :id="id"
      :placeholder="labelText"
      v-allow.number.phone
      @keyup="$emit('clearError')"
    />
    <label v-text="labelText" :for="id"></label>
    <slot name="icon"></slot>
  </div>
  <!-- <div class="input-group" :class="activeClass">
    <input
      class="form-control"
      :type="type"
      :maxlength="inputlength"
      autocomplete="off"
      ref="value"
      :value="value"
      @input="$emit('input', $event.target.value)"
      @focus="focusIn = true"
      @blur="focusIn = false"
      :disabled="loading"
      :id="id"
      v-allow.number.phone
      @keyup="$emit('clearError')"
    />
    <label v-text="labelText" @click="inputClick"></label>
    <slot name="icon" v-if="icon" class="icon"></slot>
  </div> -->
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    inputlength: {
      type: Number,
    },
    labelText: {
      type: String,
    },
    value: {
      type: String,
    },
    type: {
      type: String,
      default: "text",
    },
    disabled: {
      type: String,
    },
    id: {
      type: String,
    },
    error: {
      type: Boolean,
    },
  },
  // model: {
  //     prop: "name",
  //     event: "input"
  //   },
  data() {
    return {
      isLoading: false,
      focusIn: false,
      // icon: false,
    };
  },
  computed: {
    ...mapState({ state: (state) => state }),
    /*activeClass() {
      let check = "";
      if (this.value == "" || this.focusIn == true) {
        check = "";
      }
      if (this.value != "" && this.focusIn == true) {
      }
      if (this.focusIn == true || this.value != "") {
        check = "active";
      }
      if (this.focusIn == false && this.value != "") {
        check = "focus-active";
      }
      if (this.disabled == true) {
        check = "is-disabled";
      }
      return check;
    },*/
    /*  icon() {
      let temp = false;
      if (this.value == "" || this.focusIn) temp = false;
      if (this.value != "" && this.focusIn) temp = true;
      if (!this.focusIn && this.value != "") temp = true;

      return temp;
    },*/
    loading() {
      return this.state.sessions.loading;
    },
  },
  methods: {
    // inputClick() {
    //   if (this.disabled == false) {
    //     this.focusIn = true;
    //     this.$refs.value.focus();
    //   } else {
    //     return false;
    //   }
    // },
    updateModel(event) {
      this.$emit("input", event.target.value);
    },
  },
};
</script>
