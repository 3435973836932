import store from "../store/index";

/*const state = {
  isLoggedIn: false,
  user: {null}
}
*/


const state = {
  organisation_id: 0,
  user_id: 0,
  forceLogin: 0,
  loading: false,
  syncLoading: false,
  user: {},
  session: {
    time: "",
    username: "",
    type: "",
    required: "",
    denomination: "",
    last_session_print_data: "",
    id: 0,
  },
};


const getters = {
  isLoggedIn: state => state.isLoggedIn,
  user: state => state.user

}

const mutations = {
  login: (state, user) => {
    state.isLoggedIn = true
    state.user = user
  },
  logout: state => {
    state.isLoggedIn = false
    state.user = null
  },

  clearLoginData(state) {
    state.user_id = 0;
    state.organisation_id = 0;

    console.log('asdfasd')
  },
}

const actions = {
  login: ({ commit, dispatch, rootState, rootGetters }, data) => {

    return new Promise((resolve, reject) => {
      commit("login");
      axios
        .post(data.url, data)
        .then((res) => {

        })
      })
    // make API call to login user
    //commit('login', user)
  },
  logout: ({ commit }) => {
    // make API call to logout user
    commit('logout')
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}