<template>
  <div class="content">
    <div class="col-md-6 offset-md-3">
      <div class="full-length">
        <div class="mid-content col-md-8 col-md-8">
          <img src="../assets/img/logo.svg" alt="gone-logo" />
          <h4>Log in to Ginesys One Admin</h4>
          <form @submit.prevent="login">
            <div class="has-feedback mb-4">
              <div class="form-group">
                <div class="form-floating">
                  <input
                    id="login-email"
                    class="form-control"
                    autocomplete="off"
                    type="email"
                    placeholder="Email"
                    name="email"
                    v-model="email"
                    
                  /><label class="fs-14">Email</label>
                </div>
              </div>
              <div class="fs-14 my-2 d-none row">
                <span class="text-danger"></span>
              </div>
            </div>
            <div class="form-group has-feedback mb-4">
              <div class="form-floating with-icon">
                <span class="form-control-feedback zw-eye cursor-p"></span
                ><input
                  id="login-password"
                  type="password"
                  class="form-control"
                  autocomplete="off"
                  placeholder="Enter Password"
                  name="password"
                  v-model="password"
                  
                /><label for="password" class="fs-14">Password</label>
              </div>
            </div>
          <div class="mb-4 row">
            <div class="col-md-12">
              <Transition name='fade' >
                <div v-if="show" class="text-danger">{{message}}</div>
              </Transition>
          </div>
          </div>

            <div class="mb-4 row">
              <div class="col-md-12">
                <button
                  type="submit"
                  id="continueBtn"
                  class="w-100 login-btn fs-14 btn btn-primary btn btn-primary"
                >
                  Log in
                </button>
              </div>
            </div>
            <div class="forget-row mb-4 invisible row">
              <div class="text-right col-md-6">
                <span class="a-r fs-14">Forgot Password?</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <span class="a-r fs-14 text-grey text-decoration-none"
                  >Privacy Policy and Terms of Service</span
                >
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<!--  Auth.login(userRegistation).then((res) => {
   if (res.status === 200) {
     dispatch(login(res.data.token));
     dispatch(loading(true));
     navigate("/manage", { replace: true });
   }
   }); -->
<script>
   import ZWInput from "../components/ZWInput.vue";
   import Auth from '@/services/auth.service.js';
   //import { mapState, mapGetters } from "vuex";

   import { mapGetters, mapMutations, mapActions , mapState} from 'vuex'


   export default {
     name: "Login",
     props: {
       msg: String,
     },
     components: { ZWInput },
     computed: {
      ...mapState({
          state: (state) => this.$store.user,
      }),
      ...mapGetters(['isLoggedIn', 'user'])
      },
      mounted() {
        if (this.isLoggedIn) {
            this.$router.push("/manage");
        } 
      },
     data () {
       return {
         email:"",
         password:"",
         show:false,
         message:""
       }
     },
     methods:  {
       login() {
         let loginData = {};
         loginData = {
             email: this.email,
             password: this.password
            };
         Auth.login(loginData).then((result) => {
           if(result.status == 200 && result.data.status == true){

              //commit("saveDetails", res.data.data);
              //this.$store.sessions.loading = true;

              console.log("token"+result.data.token);
             this.$store.commit("getUserData",result.data.data);
             this.$store.commit("token",result.data.token);
             this.$router.push({ name: 'OrganizationList' });
             //localStorage.setItem("token", isLoggedIn);
             //console.log(this.$store.getters.isLoggedIn);

           }else{
            this.message = result.data.message;
            this.show =  true;
           }
           //console.log(result)
         } 
         ).catch((error) =>  {
           this.show =  true;
           this.message = 'Due to technical issue. Please try again';
 
          });
       }
     }
   };
</script>
<style scoped>
.content {
  height: 100vh !important;
}
.full-length {
  height: 100vh;
  position: relative;
  background-color: #fff;
}
.mid-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.mid-content h4 {
  font-size: 2rem;
  margin: 2rem 0;
  line-height: 40px;
  font-weight: 400;
}
.fs-14 {
  font-size: 0.875rem;
}
.no-click {
  cursor: none;
  background-color: #f4f4f4;
  color: #a8a8a8;
}
.btn-primary:disabled,
.btn-primary.disabled {
  background-color: #f4f4f4;
  border: 1px #f4f4f4 solid;
  color: #a8a8a8;
}
.forget-row a {
  text-decoration: none;
}
.text-grey {
  color: #6f6f6f;
  text-decoration: none;
}
.login-btn {
  padding: 1rem;
  line-height: 1.43;
}
.login-pf-header #kc-page-title {
  display: none !important;
}
#kc-content-wrapper .alert {
  display: none !important;
}
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0
}
</style>
