<template>
  <div class="bgwhite">
    <div class="p-0 container-fluid">
      <div class="box-header">
        <div class="col-md-12">
          <div class="d-flex align-items-center">
            <div class="col-md-6">
              <h4 class="box-title m-0 fw-5">Users</h4>
            </div>
            <div class="text-end col-md-6">
              <router-link class="dropdown-item" to="/add-user" >
                <button type="button" class="custom-primary btn btn-primary">
                    New Users
                </button>
                </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="card-org">
        <div class="sc-dnwKUv gNJUsR" style="display: none">
          <div class="sc-fLQRDB jPphKt">
            <div class="sc-beqWaB fRrkHJ rdt_Table" role="table">
              <div class="sc-ivnCJf gUHoGv">
                <div style="padding: 24px">There are no records to display</div>
              </div>
            </div>
          </div>
        </div>
        <!-- <button @click="deselectRows">deselect rows</button> -->
        <ag-grid-vue
          class="ag-theme-alpine"
          style="height: 480px"
          :columnDefs="columnDefs.value"
          :rowData="rowData.value"
          :defaultColDef="defaultColDef"
          :pagination="true"
          rowSelection="multiple"
          animateRows="true"
          @cell-clicked="cellWasClicked"
          @grid-ready="onGridReady"
          @floatingFilter = true
        >
        </ag-grid-vue>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import axios from "axios";
import { AgGridVue } from "ag-grid-vue3"; // the AG Grid Vue Component
import { reactive, onMounted, ref } from "vue";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

export default {
  name: "OrganizationList",
  components: {
    AgGridVue,
  },
  setup() {
    const gridApi = ref(null); // Optional - for accessing Grid's API

    // Obtain API from grid's onGridReady event
    const onGridReady = (params) => {
      gridApi.value = params.api;
    };

    const rowData = reactive({}); // Set rowData to Array of Objects, one Object per Row

    // Each Column Definition results in one Column.
    const columnDefs = reactive({
      value: [
        { field: "full_name" },
        { field: "name" },
        { field: "roles" },
        { field: "email" },
        { field: "phone" },
        { field: "status" },
      ],
    });

    // DefaultColDef sets props common to all Columns
    const defaultColDef = {
      sortable: true,
      filter: true,
      flex: 1,
      floatingFilter: true,
    };

    // Example load data from server
    onMounted(() => {
      fetch("https://www.ag-grid.com/example-assets/row-data.json")
        .then((result) => result.json())
        .then((remoteRowData) => (rowData.value = remoteRowData));
    });

    return {
      onGridReady,
      columnDefs,
      rowData,
      defaultColDef,
      cellWasClicked: (event) => {
        // Example of consuming Grid Event
        console.log("cell was clicked", event);
      },
      deselectRows: () => {
        gridApi.value.deselectAll();
      },
    };
  },
  mounted: function () {
    this.getUserList();
  },

  methods: {
    getUserList() {
      let self = this;
      self.$loading = true;
      axios
        .get("api/v1/vendor/allnew")
        .then(function (response) {
          console.log(response.data.data);
          self.rowData.value = response.data.data;
        })
        .catch(function (error) {
          self.$loading = false;
        });
    },
  },
};
</script>
