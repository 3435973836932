<template>
  <div class="bgwhite">
    <div class="p-0 container-fluid">
      <div class="box-header">
        <img class="loader-box" v-if="loading" src="../../assets/img/loader/Loader_720px.gif" alt="loader">
        <div class="col-md-12">
          <div class="d-flex align-items-center row">
            <div class="col-md-6">
              <!-- <a class="text-primary mb-4" href="/manage">← Enterprises</a> -->
            <router-link to="/manage" style="text-decoration: none; color: #575757">
              ← Enterprises
            </router-link>

            <h4 class="box-title m-0 fs-20 fw-5">New Enterprise</h4>
            </div>
            <div class="text-end col-md-6 pr-0">
                <router-link to="/manage" style="text-decoration: none; color: #575757">
                  <button type="button" class="text-button mr-2 btn btn-primary">
                    Discard
                  </button></router-link>
                <button type="button" class="custom-primary btn btn-primary" :disabled="btnDisable" @click="create">
                Save Enterprise
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-add-org">
        <!--  -->
        <form >
        <div class="text-left col-md-12">
          <h4 class="fs-20 fw-5 text-start box-title">Basic Details</h4>
        </div>
        <div class="mb-5 col-md-12">
          <div class="d-flex align-items-center row">
            <div class="col-md-8">
              <div class="mb-3 row">
                <div class="text-start col-md-6">
                  <label class="form-label"
                    >Brand Name<span class="text-danger">*</span></label
                  ><input
                    placeholder="Enter Brand Name"
                    autocomplete="off"
                    name="name"
                    type="text"
                    class="form-control"
                    v-model="name"
                  />
                    <span
                     class="help-block text-danger"
                     v-for="sn in errors.name"
                     v-html="sn"
                   ></span>

                </div>
                <div class="text-start col-md-6">
                  <label class="form-label"
                    >Legal Name <span class="text-danger">*</span></label
                  ><input
                    placeholder="Enter Legal Name"
                    autocomplete="off"
                    name="legal_name"
                    type="text"
                    class="form-control"
                    v-model="legal_name"
                  />
                  <span
                     class="help-block text-danger"
                     v-for="sn in errors.legal_name"
                     v-html="sn"
                   ></span>

                </div>
              </div>
              <div class="mb-3 row">
                <div class="text-start col-md-6">
                  <label class="form-label"
                    >Ref Code <span class="text-danger">*</span></label
                  ><input
                    placeholder="Enter Ref Code "
                    autocomplete="off"
                    name="text"
                    type="text"
                    class="form-control"
                    v-model="ref_code"
                  />
                  <span
                     class="help-block text-danger"
                     v-for="sn in errors.ref_vendor_code"
                     v-html="sn"
                   ></span>
                </div>

                <div class="text-start col-md-6">
                  <label class="form-label"
                    >Email <span class="text-danger">*</span></label
                  ><input
                    placeholder="Enter email"
                    autocomplete="off"
                    name="email"
                    type="email"
                    class="form-control"
                    v-model="email"
                  />
                  <span
                     class="help-block text-danger"
                     v-for="sn in errors.email"
                     v-html="sn"
                   ></span>

                </div>
              </div>
              <div class="mb-3 row">
                <div class="text-start col-md-6">
                  <label class="form-label">Phone
                    <span class="text-danger">*</span>
                  </label>
                  <input
                    maxlength="10"
                    placeholder="Enter Phone"
                    autocomplete="off"
                    name="phone"
                    type="text"
                    class="form-control"
                    v-model="phone"
                  />
                  <span
                     class="help-block text-danger"
                     v-for="sn in errors.phone"
                     v-html="sn"
                   ></span>
                </div>


                <div class="text-start col-md-6">
                  <label class="form-label">Alternate Phone</label
                  ><input
                    maxlength="10"
                    placeholder="Enter Alternate Phone"
                    autocomplete="off"
                    name="phone_2"
                    type="text"
                    class="form-control"
                    v-model="phone_2"
                  />
                  <span
                     class="help-block text-danger"
                     v-for="sn in errors.phone_2"
                     v-html="sn"
                   ></span>
                </div>
              </div>


              <div class="mb-3 row">


                <div class="text-start col-md-6">
                  <label class="form-label">Organization Type</label
                  >
                  <select
                    aria-label="Default select example"
                    name="category_type"
                    class="form-select"
                    v-model="category_type"
                  >
                    <option value="LIVE">LIVE</option>
                    <option value="DEMO">DEMO</option>
                    <option value="UAT">UAT</option>
                  </select>

                  <span
                     class="help-block text-danger"
                     v-for="sn in errors.phone_2"
                     v-html="sn"
                   ></span>
                </div>
                
                <div class="text-start col-md-6">
                  <label class="form-label">BA Number
                    <span class="text-danger" v-if="category_type=='LIVE'">*</span>
                  </label>
                  <input
                    maxlength="10"
                    placeholder="BA Number"
                    autocomplete="off"
                    name="client_id"
                    type="text"
                    class="form-control"
                    v-model="client_id"
                  />
                  <span
                     class="help-block text-danger"
                     v-for="sn in errors.client_id"
                     v-html="sn"
                   ></span>
                </div>
              </div>
              <div class="mb-3 row">
                <div class="text-start col-md-6">
                  <label class="form-label"
                    >Status<span class="text-danger">*</span></label
                  >
                  <select
                    aria-label="Default select example"
                    name="status"
                    class="form-select"
                    v-model="status"
                  >
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                  <span
                     class="help-block text-danger"
                     v-for="sn in errors.status"
                     v-html="sn"
                   ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-left col-md-12">
          <h4 class="fs-20 fw-5 text-start box-title">Registered Address</h4>
        </div>
        <div class="mb-5 col-md-12">
          <div class="d-flex align-items-center row">
            <div class="col-md-8">
              <div class="mb-3 row">
                <div class="text-start col-md-12">
                  <label class="form-label">Address<span class="text-danger">*</span></label
                  ><input
                    placeholder="Enter Registered Address"
                    autocomplete="off"
                    name="registered_address"
                    type="text"
                    class="form-control"
                    v-model="registered_address"
                  />
                  <span
                     class="help-block text-danger"
                     v-for="sn in errors.registered_address"
                     v-html="sn"
                   ></span>                  
                </div>
              </div>
              <div class="mb-3 row">
                <div class="text-start col-md-6">
                  <label class="form-label">Pincode<span class="text-danger">*</span></label
                  ><input
                    maxlength="6"
                    placeholder="Enter Pincode"
                    autocomplete="off"
                    name="registered_address_pincode"
                    type="text"
                    class="form-control"
                    v-model="registered_pincode"
                  />
                  <span
                     class="help-block text-danger"
                     v-for="sn in errors.registered_pincode"
                     v-html="sn"
                   ></span>  
                </div>
                <div class="text-start col-md-6">
                  <label class="form-label">Country</label>
                  <select
                    aria-label="Default select example"
                    name="registered_country"
                    class="form-select"
                    v-model="registered_country"
                    @click="stateList('register'),getTimeZone()"
                  >
                  <option value="" disabled selected="true">Choose Country</option>
                   <option v-for="option in coutryOption" :key="option.id" :value="option.id">{{ option.name }}</option>
                  </select>
                  <span
                     class="help-block text-danger"
                     v-for="sn in errors.registered_country"
                     v-html="sn"
                   ></span>  
                </div>
              </div>
              <div class="mb-3 row">
                <div class="text-start col-md-6">
                  <label class="form-label">State
                    <span class="text-danger">*</span>
                  </label>
                  <select
                    aria-label="Default select example"
                    name="registered_address_state"
                    class="form-select"
                    v-model="registered_state"
                    @click="cityList('register')"
                  >
                   <option value="" disabled selected="true">Choose State</option>
                   <option v-for="option in stateOption" :key="option.id" :value="option.id">{{ option.name }}</option>
                  </select>
                  <span
                     class="help-block text-danger"
                     v-for="sn in errors.registered_state"
                     v-html="sn"
                   ></span>  
                </div>
                <div class="text-start col-md-6">
                  <label class="form-label">City 
                    <span class="text-danger">*</span>
                  </label>
                  <select
                    aria-label="Default select example"
                    name="registered_address_city"
                    class="form-select"
                    v-model="registered_city"
                  >
                   
                   <option value="" disabled selected="true">Select City</option>
                   <option v-for="option in cities" :key="option.id" :value="option.id">{{ option.name }}</option>
                  </select>
                  <span
                     class="help-block text-danger"
                     v-for="sn in errors.registered_city"
                     v-html="sn"
                   ></span>  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-left col-md-12">
          <h4 class="fs-20 fw-5 text-start box-title">Corporate Address</h4>
        </div>
        <div class="mb-5 col-md-12">
          <div class="d-flex align-items-center row">
            <div class="col-md-8">
              <div class="mb-3 d-flex align-items-center row">
                <div class="text-start col-md-6">
                  <label class="form-label">Address</label
                  ><input
                    placeholder="Enter Corporate Address"
                    autocomplete="off"
                    name="corporate_address"
                    type="text"
                    class="form-control"
                    v-model="address"
                  />
                   <span
                     class="help-block text-danger"
                     v-for="sn in errors.address"
                     v-html="sn"
                   ></span>  
                </div>
                <div class="text-end col-md-6">
                  <div class="mt-4 form-check form-check-inline">
                    <input
                      name="is_corporate_registered_same"
                      type="checkbox"
                      class="form-check-input"
                      v-model="same_as_register"
                      @click="sameAsRegister()"
                    /><label title="" class="form-check-label"
                      >Same as Registered Address</label
                    >
                  </div>
                </div>
              </div>
              <div class="mb-3 row">
                <div class="text-start col-md-6">
                  <label class="form-label">Pincode</label
                  ><input
                    placeholder="Enter Pincode"
                    maxlength="6"
                    autocomplete="off"
                    name="corporate_address_pincode"
                    type="text"
                    class="form-control"
                    v-model="pincode"
                  />
                  <span
                     class="help-block text-danger"
                     v-for="sn in errors.pincode"
                     v-html="sn"
                   ></span>  
                </div>
                <div class="text-start col-md-6">
                  <label class="form-label">Country</label>
                  <select
                    aria-label="Default select example"
                    name="registered_address_country"
                    class="form-select"
                    v-model="country"
                    @click="stateList('corporate')"
                  >
                  <option value="" disabled selected="true">Choose Country</option>
                  <option v-for="option in coutryOption" :key="option.id" :value="option.id">{{ option.name }}</option>
                  </select>
                  <span
                     class="help-block text-danger"
                     v-for="sn in errors.country"
                     v-html="sn"
                   ></span>  
                </div>
              </div>
              <div class="mb-3 row">
                <div class="text-start col-md-6">
                  <label class="form-label">State</label>
                  <select
                    aria-label="Default select example"
                    name="corporate_address_state"
                    class="form-select"
                    v-model='state'
                    @click="cityList('corporate')"
                  >

                  <option value="" disabled selected="true">Choose State</option>
                   <option v-for="option in stateOption" :key="option.id" :value="option.id">{{ option.name }}</option>

                  </select>
                   <span
                     class="help-block text-danger"
                     v-for="sn in errors.state"
                     v-html="sn"
                   ></span> 
                </div>
                <div class="text-start col-md-6">
                  <label class="form-label">City</label>
                  <select
                    aria-label="Default select example"
                    name="corporate_address_city"
                    class="form-select"
                    v-model="city"
                  >
                   <option value="" disabled selected="true">Select City</option>
                   <option v-for="option in cities" :key="option.id" :value="option.id">{{ option.name }}</option>

                  </select>
                  <span
                     class="help-block text-danger"
                     v-for="sn in errors.city"
                     v-html="sn"
                   ></span> 
                </div>
              </div>
              <div class="row">
                <div class="text-start col-md-6">
                  <label class="form-label"
                    >Time Zone <span class="text-danger">*</span></label
                  >
                  <select
                    aria-label="Default select example"
                    name="time_zone_id"
                    class="form-select"
                    v-model="timezone"
                  >
                  <option value="" disabled selected="true">Select Timezone</option>
                  <option v-for="option in timezoneOption" :key="option.id" :value="option.id">{{ option.name }}</option>
                  </select>
                  <span
                     class="help-block text-danger"
                     v-for="sn in errors.time_zone_id"
                     v-html="sn"
                   ></span> 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-left col-md-12">
          <h4 class="fs-20 fw-5 text-start box-title">Document details</h4>
        </div>
        <div class="mb-5 col-md-12">
          <div class="d-flex align-items-center row">
            <div class="col-md-8">
              <div class="mb-3 row">
                <div class="text-start col-md-6">
                  <label class="form-label"
                    >Pan Number<span class="text-danger">*</span></label
                  ><input
                    placeholder="Enter Pan Number"
                    autocomplete="off"
                    MaxLength="10"
                    name="pan_no"
                    type="text"
                    class="form-control"
                    v-model="pan_number"
                    v-on:input="validatePanNumber"
                  />
                <span
                     class="help-block text-danger"
                     v-for="sn in errors.pan_no"
                     v-html="sn"
                   ></span> 
                </div>
                <div class="text-start col-md-6">
                  <label class="form-label">GST Number</label
                  ><input
                    placeholder="Enter GST Number"
                    autocomplete="off"
                    name="gst_no"
                    type="text"
                    class="form-control"
                    v-model="gst_number"
                  />
                  <span
                     class="help-block text-danger"
                     v-for="sn in errors.gst_no"
                     v-html="sn"
                   ></span> 
                </div>
              </div>
              <div class="mb-3 row">
                <div class="text-start col-md-6">
                  <label class="form-label">Pan Image</label
                  ><input
                    placeholder="Select Pan Image"
                    autocomplete="off"
                    name="pan"
                    type="file"
                    class="form-control"
                  />
                  <span
                     class="help-block text-danger"
                     v-for="sn in errors.pan"
                     v-html="sn"
                   ></span> 
                </div>
                <div class="text-start col-md-6">
                  <label class="form-label">GST Image</label
                  ><input
                    placeholder="Select GST Image"
                    autocomplete="off"
                    name="gst"
                    type="file"
                    class="form-control"
                  />
                  <span
                     class="help-block text-danger"
                     v-for="sn in errors.gst"
                     v-html="sn"
                   ></span> 
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="text-left col-md-12">
          <h4 class="fs-20 fw-5 text-start box-title">Vendor Setting</h4>
        </div>
        <div class="mb-5 col-md-12">
          <div class="d-flex align-items-center row">
            <div class="col-md-8">
              <div class="mb-3 row">
                <div class="text-start col-md-6">
                  <label class="form-label"
                    >Allow user<span class="text-danger">*</span></label
                  ><input
                    autocomplete="off"
                    placeholder="Enter Allow user"
                    type="number"
                    class="form-control"
                    v-model="allow_user"
                    min="1"
                    oninput="validity.valid||(value='');"
                   />
                  <span
                     class="help-block text-danger"
                     v-for="sn in errors.allow_user"
                     v-html="sn"
                   ></span> 
                </div>
                <div class="text-start col-md-6">
                  <label class="form-label"
                    >Allow Store<span class="text-danger">*</span></label
                  ><input
                    autocomplete="off"
                    name="allow_store"
                    placeholder="Enter Allow Store"
                    type="number"
                    class="form-control"
                    v-model="allow_store"
                    min="1"
                    oninput="validity.valid||(value='');"
                   />
                   <span
                     class="help-block text-danger"
                     v-for="sn in errors.allow_store"
                     v-html="sn"
                   ></span> 
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="text-left col-md-12">
          <h4 class="fs-20 fw-5 text-start box-title">Logo</h4>
        </div>
        <div class="col-md-12 mb-5">
          <div class="d-flex align-items-center row">
            <div class="col-md-8">
              <div class="container p-0">
                <div class="file-wrapper">
                <div>
                  <img :src="imageUrl" alt="Preview" v-if="imageUrl" @click="selectImage" class="preview-image">
                  <input type="file" @change="handleFileUpload" ref="fileInput">
                 </div>
                  <div class="drag-place w-25">
                    <span class="text-primary fs-13"></span>
                    <p class="m-0 fs-13 text-primary mt-2">
                      Select image file here
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>


      <form v-if="id == null">
        <div class="text-left col-md-12">
          <h4 class="fs-20 fw-5 text-start box-title">User Details</h4>
        </div>
        <div class="mb-5 col-md-12">
          <div class="d-flex align-items-center row">
            <div class="col-md-8">
              <div class="mb-3 row">
                <div class="text-start col-md-6">
                  <label class="form-label"
                    >First Name<span class="text-danger">*</span></label
                  >
                  <input
                    placeholder="Enter First Name"
                    autocomplete="off"
                    v-model="user_detail.first_name"
                    name="first_name"
                    type="text"
                    class="form-control"
                  />
                  <span
                     class="help-block text-danger"
                     v-for="sn in user_detail.errors.first_name"
                     v-html="sn"
                   ></span>
                </div>
                <div class="text-start col-md-6">
                  <label class="form-label"
                    >Last Name<span class="text-danger">*</span></label
                  >
                  <input
                    placeholder="Enter Last Name"
                    autocomplete="off"
                    v-model="user_detail.last_name"
                    name="last_name"
                    type="text"
                    class="form-control"
                  />
                  <span
                     class="help-block text-danger"
                     v-for="sn in user_detail.errors.last_name"
                     v-html="sn"
                   ></span>
                </div>
              </div>
              <div class="mb-3 row">
                <div class="text-start col-md-6">
                  <label class="form-label"
                    >Email <span class="text-danger">*</span></label
                  >
                  <input
                    placeholder="Enter email"
                    autocomplete="off"
                    v-model="user_detail.email"
                    name="user_email"
                    type="email"
                    class="form-control"
                  />
                  <span
                     class="help-block text-danger"
                     v-for="sn in user_detail.errors.email"
                     v-html="sn"
                   ></span>
                </div>
                <div class="text-start col-md-6">
                  <label class="form-label">Phone Number</label>
                  <input
                    maxlength="10"
                    placeholder="Enter Phone"
                    autocomplete="off"
                    name="mobile"
                    v-model="user_detail.mobile"
                    type="text"
                    class="form-control"
                  />
                  <span
                     class="help-block text-danger"
                     v-for="sn in user_detail.errors.mobile"
                     v-html="sn"
                   ></span>
                </div>
              </div>

               <div class="mb-3 row">
               <div class="text-start col-md-6">
                  <label class="form-label"
                    >Status<span class="text-danger">*</span></label
                  >
                  <select
                    aria-label="Default select example"
                    name="status"
                    class="form-select" 
                    v-model="user_detail.status"
                  >

                   <option value="" disabled selected="true">Choose Status</option>
                   <option v-for="option in statusOptions" :key="option.value" :value="option.value">{{ option.name }}</option>
                  </select>
                  <span class="help-block text-danger" v-for="sn in user_detail.errors.status" v-html="sn"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="text-left col-md-12">
            <!-- <h4 class="fs-16 text-start mb-4">Password</h4> -->
            <h4 class="fs-20 fw-5 text-start box-title">Password</h4>

          </div>

          
          <div class="d-flex align-items-center row">
            <div class="col-md-8">
              <div class="mb-3 row">
                <div class="text-start col-md-6">
                  <label class="form-label"
                    >Password<span class="text-danger">*</span></label
                  >
                  <input
                    placeholder="Password"
                    autocomplete="off"
                    name="password"
                    v-model="user_detail.password"
                    type="password"
                    class="form-control"
                  />
                <span class="help-block text-danger" v-for="sn in user_detail.errors.password" v-html="sn"></span>
                </div>
                <div class="text-start col-md-6">
                  <label class="form-label"
                    >Confirm Password<span class="text-danger">*</span></label
                  >
                  <input
                    placeholder="Confirm Password"
                    autocomplete="off"
                    name="confirm_password"
                    v-model="user_detail.confirm_password"
                    type="password"
                    class="form-control"
                  />
                  <span class="help-block text-danger" v-if="user_detail.password != user_detail.confirm_password && user_detail.confirm_password != ''"  >Password mismatch</span>

                  <span class="help-block text-danger" v-for="sn in user_detail.errors.confirm_password" v-html="sn"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import Multiselect from 'vue-multiselect'
import axios from "axios";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
  props: {
    id: Number,
    default:0,
  },
  name: "AddOrganization",
   data(){
    return {
      selectedFile: null,
      loading:false,
      name:"",
      legal_name:"",
      status:"",
      db_structure:"1",
      vendor_code:"",
      phone:"",
      phone_2:"",
      email:"",
      parent_id:"0",
      is_parent:"",
      image_preview:"",
      db_type:"1",
      db_config_id:"1",
      allow_user:"",
      allow_store:"",
      address:"",
      country:"",
      state:"",
      city:"",
      pincode:"",
      time_zone_id:"",
      registered_address:"",
      registered_country:"",
      registered_state:"",
      registered_city:"",
      registered_pincode:"",
      gst_no:"",
      pan_number:"",
      pan:"",
      ref_vendor_code:"",
      client_id:"",
      category_type:"LIVE",
      ref_code:"",
      errors:[],
      timezone:"",
      gst_number:"",
      coutryOption:[],
      stateOption:[],
      registerStateOption:[],
      cities: [],
        timezoneOption:[],
        same_as_register:"",
        imageUrl:'',
        user_detail:{
          first_name: "",
          last_name: "",
          email: "",
          mobile: "",
          status: "",
          vendor: "",
          emailVerified: false,
          password: "",
          confirm_password: "",
          vendors: [],
          errors:[]
        },
        statusOptions: [
          {name: 'Active', value: 1, },
          {name: 'InActive', value: 0, },
        ],
        vendorsList: [],
        btnDisabled:true,
    }
  },
  mounted(){
    this.countryList();
    this.getOrgnizationDetail();
  },
  computed:{
      btnDisable(){
        // this.btnDisabled = true;
       let self  = this;
       let check = true;
        if(
         self.name != ''
         && self.legal_name != ''
         && self.ref_code != ''
         && self.email != ''
         && self.phone != ''
         && self.pan_number != ''
         && self.registered_address != ''
         && self.registered_pincode != ''
         && self.registered_state != ''
         && self.registered_city != ''
         && self.allow_user != ''
         && self.allow_store != ''
         && self.user_detail.first_name != ''
         && self.user_detail.last_name !='' 
         && self.user_detail.email !='' 
         && self.user_detail.mobile !='' 
         && self.user_detail.password != '' 
         && self.user_detail.confirm_password != '' 
         && self.user_detail.password == self.user_detail.confirm_password
         
         ){
          
          if(self.category_type == "LIVE" && self.client_id == "" ){
              self.btnDisabled = true;
              check = true;
          }else{
              self.btnDisabled = false;            
              check = false;
          }
 
        }
       
        if(self.id != null){
          // check = false;
          self.btnDisabled = false;
          check  = false;
        }
       return check;
      },
  },

   /*watch: {
      pan_number: function (){
          this.validatePanNumber();
      },
    },*/
  methods:{

    // uplad image

    handleFileUpload(event) {
      // console.log("mansi");
      this.selectedFile = event.target.files[0];
      
      this.previewImage();
    },
    previewImage() {
      if (this.selectedFile && this.selectedFile instanceof Blob) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.imageUrl = e.target.result;
          };
          reader.readAsDataURL(this.selectedFile);
     }else {
        console.error('Invalid file selected.');
     }
    },
    selectImage () {
      this.$refs.fileInput.click()
    },
    // uplad image end

    // pan card validation
      validatePanNumber() { 
        var panVal = this.pan_number;
        var regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
        if(panVal.match(regpan)){
           this.errors.pan_no = '';
           //this.btnDisabled = false;
        } else {
           this.errors.pan_no = "invalid pan card number";
           this.btnDisabled = true;
        }    
      },
    // pan card validation end
    countryList() {
      let self = this;
      self.$loading = true;
      axios
        .get("api/v1/country/list")
        .then(function (response) {
          self.coutryOption = response.data;
        })
        .catch(function (error) {
          self.$loading = false;
        });
    },
    stateList(type) {
      let self = this;
      let id = 0;
      if(type=='register'){
        id = self.registered_country
      }
      if(type=='corporate'){
        id = self.country
      }
      if(id == 0){
        return false;
      }
      self.$loading = true;
      axios
        .get("api/v1/state/list/"+id)
        .then(function (response) {
          if(type=='register'){
            self.stateOption = response.data;  
          }
          if(type=='corporate'){
            self.stateOption = response.data;  
          }
        })
        .catch(function (error) {
          self.$loading = false;
        });
    },

    cityList(type){


      let self = this;
      let state_id = 0;
      if(type=='register'){
        state_id = self.registered_state
      }
      if(type=='corporate'){
        state_id = self.state
      }
      if(state_id == 0){
        return false;
      }
      self.$loading = true;
      axios
        .get("api/v1/state/city/"+state_id)
        .then(function (response) {
          if(type=='register'){
            self.cities = response.data;  
          }
          if(type=='corporate'){
            self.cities = response.data;  
          }
        })
        .catch(function (error) {
          self.$loading = false;
        });
    


    },
    getTimeZone(){
      let self = this;
      self.$loading = true;

      axios
        .get("api/v1/country/zone/"+this.registered_country)
        .then(function (response) {
          self.timezoneOption = response.data;

          if(response.data.length == 1){
            self.timezone = response.data[0].id 
          }


        })
        .catch(function (error) {
          self.$loading = false;
        });
    },
    create(){
     let self = this;
     self.loading = true;

     let enterpriseData = {
         id:self.id == null?0:self.id,
         name: self.name,
         legal_name: self.legal_name,
         status: self.status,
         db_structure: self.db_structure,
         vendor_code: self.vendor_code,
         phone: self.phone,
         phone_2: self.phone_2,
         email: self.email,
         parent_id: self.parent_id,
         image_preview: self.image_preview,
         db_type: self.db_type,
         db_config_id: self.db_config_id,
         allow_user: self.allow_user,
         allow_store: self.allow_store,
         address: self.address,
         country: self.country,
         state: self.state,
         city: self.city,
         time_zone_id: self.time_zone_id,
         registered_address: self.registered_address,
         registered_country: self.registered_country,
         registered_state: self.registered_state,
         registered_city: self.registered_city,
         registered_pincode: self.registered_pincode,
         pincode: self.pincode,
         gst_no: self.gst_number,
         pan_no: self.pan_number,
         pan: self.pan,
         ref_vendor_code: self.ref_code,
         client_id: self.client_id,
         category_type: self.category_type,
         time_zone_id:self.timezone,
         vendor_image:self.imageUrl,
         
        };


      axios.post("api/v1/vendor/create", enterpriseData).then((response) => {
        if(response.data.status == 'success'){
          self.loading = false;
          toast.success(response.data.message);
          self.user_detail.vendor = response.data.id;

          /*Call User Create Api*/
          if(self.id == null){
            axios.post("api/v1/vendor/createuser", self.user_detail).then((response) => {

          }).catch(function(error){
              console.log(error.response.data);
              toast.error("User Detail Not Insert Due to some technical issue.");
              self.errors=error.response.data.errors;
              console.log(error.response.data.errors);
          });
          }
          /*End User Create Api*/

          self.$router.push({ name: 'OrganizationList' });
        }
      }).catch(function(error){
        //console.log(error.response.data.message);
        let errorMessage = 'Validation Failed';
        if(error.response.data.message !== undefined){
          errorMessage  = error.response.data.message;
        }
        self.loading = false;
        toast.error(error.response.data.message,{
          title:"Validation Failed"
        });
        self.errors = error.response.data;
      });
    },
    sameAsRegister(){
      let self = this;
      self.country  = self.registered_country;
      self.address  = self.registered_address;
      self.pincode  = self.registered_pincode;
      self.state  = self.registered_state;
      self.city  = self.registered_city;
    },
    onImageChange(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.$store.dispatch('setImageUrl', reader.result);
      };
    },
    getOrgnizationDetail(){
      let self  = this;
      if(self.id != ''){

        axios.get("api/v1/vendor/details/"+self.id).then((response) => {
            let result  = response.data.data;
              console.log(response.status);
            if(response.status == 200){
              self.name =  result.name;
              self.status = result.status;
              self.legal_name =  result.legal_name;
              self.email =  result.email;
              self.ref_code =  result.ref_vendor_code;
              self.phone =  result.phone;
              self.phone_2 =  result.phone_2;
              self.address =  result.address;
              self.state =  result.state;
              self.city =  result.city;
              self.country =  result.country;
              self.pincode =  result.pincode;
              self.allow_user =  result.allow_user;
              self.allow_store =  result.allow_store;
              self.registered_address =  result.registered_address;
              self.registered_state =  result.registered_state;
              self.registered_country =  result.registered_country;
              self.registered_city =  result.registered_city;
              self.registered_pincode =  result.registered_pincode;
              self.gst_number =  result.gst_no;
              self.pan_number =  result.pan_no;
              self.timezone =  result.time_zone_id;
              self.client_id =  result.client_id;
              self.category_type =  result.category_type;
             }

        }).catch(function (error) {

        })
      }
    }
  }
};
</script>
<style>
@import url("../../assets/styles/themes/_default.scss");
.preview-image{
  cursor: pointer;
}
</style>
