<template>
  <div v-if="status == 1" class="status-green"><spam class="active-dot"></spam> Active</div>
  <div class="deactive-status" v-else> <spam class="deactive-dot"></spam>InActive</div>
</template>

<script>
export default {
  props: ['params'], // Declare the 'params' prop to receive values from AgGrid
  name: "StatusDot",
  data() {
    return {
       status:'',
       message:'',
    };
  },
  computed: {
    status() {
      this.status = this.params.value
      this.message = this.params.value == '1'?'Active':'Inactive';

      console.log(this.status);
      return this.params.value; // Access the value from 'params'
    },
    // statusClass() {
    //   // Add any CSS classes based on the status value if needed
    //   return {
    //     'status-red': this.status === '0',
    //     'status-green': this.status === '1',
    //   };
    // },
  },

};
</script>

<style scoped>
  .deactive-status {
    border-radius: 25px;
    position: relative;
    display: flex;
    text-align:center;
    align-items: center;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0.25rem 0.75rem 0.25rem 1.5rem;
    font-size: 11px;
    background-color: #f6f6f6;
    color: var(--grey-60);
    line-height: 1rem;
    margin-top:5px;
  }

  .active-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #33cc5c;
    position: absolute;
    left: 0.5rem;
  }
  .deactive-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #808080bd;
      position: absolute;
      left: 0.5rem;
   }

.status-green {
    border-radius: 25px;
    position: relative;
    display: flex;
    text-align:center;
    align-items: center;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0.25rem 0.75rem 0.25rem 1.5rem;
    font-size: 11px;
    background-color: #bbfbcc;
    color: var(--grey-60);
    line-height: 1rem;
    margin-top:5px;
}
</style>