<template>
  <div class="basic-info p-32">
    <div>
      <h6 class="fs-20 fw-5 box-title">Basic Details</h6>
      <div class="mb-5 col-md-12">
        <div class="d-flex align-items-center row">
          <div class="col-md-8">
            <div class="mb-4 row">
              <div class="text-start col-md-6">
                <label class="grey-60 fs-12 mb-1">First Name</label>
                <p class="m-0 fs-14 text-black">{{userdetail.first_name}}</p>
              </div>
              <div class="text-start col-md-6">
                <label class="grey-60 fs-12 mb-1">Last Name</label>
                <p class="m-0 fs-14 text-black">{{userdetail.last_name}}</p>
              </div>
            </div>
            <div class="mb-4 row">
              <div class="text-start col-md-6">
                <label class="grey-60 fs-12 mb-1">Email</label>
                <p class="m-0 fs-14 text-black">{{userdetail.email}}</p>
              </div>
              <div class="text-start col-md-6">
                <label class="grey-60 fs-12 mb-1">Phone</label>
                <p class="m-0 fs-14 text-black">{{userdetail.mobile}}</p>
              </div>
            </div>
            <div class="mb-4 row">
              <div class="text-start col-md-6">
                <label class="grey-60 fs-12 mb-1">Alternate Phone</label>
                <p class="m-0 fs-14 text-black">{{userdetail.mobile}}</p>
              </div>
              <div class="text-start col-md-6">
                <label class="grey-60 fs-12 mb-1">Status</label>
                <p class="m-0 fs-14 text-black">{{userdetail.status}}</p>
              </div>
            </div>

            <div class="mb-4 row">
              <div class="text-start col-md-6">
                <label class="grey-60 fs-12 mb-1">Vendor Name</label>
                <p class="m-0 fs-14 text-black">test</p>
              </div>
              <div class="text-start col-md-6">
                <label class="grey-60 fs-12 mb-1">Allow Store</label>
                <p class="m-0 fs-14 text-black">Store</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "EnterprisesInfo",
  components: {

  },
 data() {
    return {
      userdetail: "",
    };
  },

  mounted: function () {
    this.getUserDetail();
  },

  methods: {
    getUserDetail() {
      let self = this;
      self.$loading = true;
      axios
        .post("api/v1/vendor/get-user-by-id", { id: 12 })
        .then(function (response) {
          self.userdetail = response.data.data;
          console.log(self.userdetail);
        })
        .catch(function (error) {
          self.$loading = false;
        });
    },
  },


};
</script>
