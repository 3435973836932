import axios from "axios";

//axios.defaults.baseURL = "http://test.onboarding.api.gozwing.com/public/";
axios.defaults.baseURL = "https://staging.onboarding.api.gozwing.com/public/";
//axios.defaults.baseURL = "http://localhost/zwing-admin-api/public/";
 //axios.defaults.baseURL = "http://localhost/zwing/zwing-admin-api/public/";
// axios.defaults.baseURL = "http://localhost/zwing/zwing-admin-api/public/";

// let token = '';
let token = localStorage.getItem('session') === null?'':JSON.parse(localStorage.getItem('session')).token;
axios.defaults.headers["Authorization"] = 'Bearer '+token;

axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";

//axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';


export default axios;
