//import { createStore } from "vuex";
import Vuex from 'vuex';
import Sessions from '../sessions/session';
import VuexPersistence from "vuex-persist";

/*export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});*/

const state = {
  organisation_id: 0,
  user_id: 0,
  forceLogin: 0,
  loading: false,
  syncLoading: false,
  user: {api_token:''},
  session: {
    time: "",
    username: "",
    type: "",
    required: "",
    denomination: "",
    last_session_print_data: "",
    id: 0,
  },
};

const vuexSessions = new VuexPersistence({
  key: "session",
  storage: window.localStorage,
  //modules: ["sessions"],
});

/*const vuexToken = new VuexPersistence({
  key: "token",
  storage: window.localStorage,
  //modules: ["sessions"],
});*/


export default new Vuex.Store({
  state:state,
  modules: {
    /*session,*/
  },
  mutations: {
    clearState(state) {
      state.user.api_token = '';
    },
    clearLoginData(state){
    	console.log('asdf');
    },
    getUserData(state, data) {
    	state.user = data;
  	},
    token(state,data){
      state.token = data
    },
    setImageUrl(state, url) {
      state.imageUrl = url;
    }

  },
  getters:{
  	 isLoggedIn: (state) => !!state.user.api_token,
  	 //isLoggedIn: (state) => true,
  	 user(state) {
      return state.user;
      //return state.user.api_token;
      },
      token(state){
        return state.token;
      }
  },
  actions:{

    setImageUrl({ commit }, url) {
      commit('setImageUrl', url);
    }


  },
  modules: {
    sessions: Sessions
  },
  plugins: [
    vuexSessions.plugin,
  ],
})




