<template>
  <div class="bgwhite">
    <div class="box-header">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6">
            <a class="text-primary mb-4" href="/user-list">← Enterprises</a>
            <h4 class="box-title m-0 fs-20 fw-5">Summary</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white d-flex main-box">
      <div class="left-tabs">
        <ul>
          <li
            :class="{ active: activeTab === 1 }"
            @click="selectConnectedAppdata(1)"
          >
            User Information
          </li>
          <li
            :class="{ active: activeTab === 2 }"
            @click="selectConnectedAppdata(2)"
          >
            User List
          </li>
        </ul>
      </div>
      <div class="right-tabs vertical-scroll">
        <EnterprisesInfo v-if="selectedTab == 1" />
        <UserTable v-if="selectedTab == 2" />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import EnterprisesInfo from "../UserInfo/EnterprisesInfo";
import UserTable from "../UserInfo/UserTable.vue";
export default {
  name: "UserView",
  components: {
    EnterprisesInfo,
     UserTable,
  },
  props: {
    enable: Boolean,
  },
  data() {
    return {
      selectedTab: 1,
      activeTab: 1,
      userdetail: "",

    };
  },
  mounted: function () {
    this.getUserList();
  },

  methods: {
    selectConnectedAppdata(val) {
      this.selectedTab = val;
      this.activeTab = val;
    },

    getUserList() {
      let self = this;
      self.$loading = true;
      axios
        .get("api/v1/vendor/allnew")
        .then(function (response) {
          console.log("response.data.data");
          self.userdetail = response.data.data;
        })
        .catch(function (error) {
          self.$loading = false;
        });
    },

  },
};
</script>
<style>
@import url("../../assets/styles/themes/_default.scss");
</style>
