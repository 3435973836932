<template>
  <div
    :class="['sidebar', { w20: open, w5: !open }]"
    @mouseover="setOpen(true)"
    @mouseout="setOpen(false)"
    v-if="isLoggedIn"
  >
    <ul class="mt-2">
      <li>
        <img
          :src="
            open ? '../img/g1logo-gradient.svg' : '../img/g1logo-gradient.svg'
          "
          alt="logo"
        />
      </li>
      <li>
        <router-link to="/manage" style="text-decoration: none; color: #575757">
          <div class="d-flex align-items-center">
            <img src="../assets/img/go-enterprise.svg" alt="home-icon" />
            <span>{{ open ? " Enterprises" : "" }}</span>
          </div>
        </router-link>
      </li>
      
<!--       <li>
        <router-link
          to="user-list"
          style="text-decoration: none; color: #575757"
        >
          <div class="d-flex align-items-center">
            <img src="../assets/img/go-user.svg" alt="user-icon" />
            <span>{{ open ? " Users" : "" }}</span>
          </div>
        </router-link>
      </li> -->

      <li>
        <div
          @click="userLogout"
          style="cursor: pointer"
          class="d-flex align-items-center"
        >
          <img src="../assets/img/go-logout.svg" alt="user-icon" />
          <span>{{ open ? " Logout" : "" }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapGetters} from 'vuex'

export default {
  name: "SideMenu",
  props: {
    msg: String,
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'user'])
  },
  data() {
    return {
      open: false,
    };
  },
  mounted() {
        if (this.isLoggedIn  == false) {
            this.$router.push("/");
        } 
      },
  methods: {
    setOpen(value) {
      this.open = value;
    },
    userLogout() {
      //localStorage.removeItem('session');
      //this.$router.push({ name: 'AddOrganization' });
      localStorage.removeItem('session');
      this.$store.commit("clearState");
      this.$router.push({ name: 'Login' });
    },
  },
};
</script>

<style scoped>
.sidebar {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  transition: all 0.5s ease;
  box-shadow: 0 1px 6px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  z-index: 999;
}
.sidebar ul {
  padding: 0;
}
.sidebar ul li {
  list-style-type: none;
  text-align: left;
  display: flex;
  align-items: center;
  min-height: 50px;
  padding: 0.75rem 1.5rem;
}
.w5.sidebar ul li {
  list-style-type: none;
  text-align: left;
}
.w5.sidebar ul li span {
  padding-left: 1rem;
  opacity: 0.4;
}
.w20.sidebar ul li span {
  opacity: 1;
  color: #161616;
  transition: ease 0.1s;
}
.toggle-btn {
  width: 25px;
  height: 25px;
  border-radius: 25%;
  background-color: #f0f0f0;
  position: absolute;
  right: -15px;
  cursor: pointer;
}
.toggle-btn img {
  position: relative;
  top: 1px;
  left: 5px;
}
.w5 {
  width: 64px !important;
}
.w20 {
  width: 20% !important;
}
.sidebar ul li:first-child {
  padding: 12px !important;
}
.sidebar ul li:last-child div {
  padding: 8px !important;
}
.sidebar ul li {
  padding: 0 12px 12px 12px;
}
.sidebar ul li a {
  padding: 8px;
}
.sidebar ul li a span,
.sidebar ul li span {
  padding-left: 8px;
  font-size: 14px;
  font-weight: 600;
}
</style>
