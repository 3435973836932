<template>
  <div class="bgwhite">
    <div class="box-header">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6">
            <a class="mb-4" @click="back()" href="javascript:void(0)" style="text-decoration: none; color: #575757">← User</a>
            <h4 class="box-title m-0 fs-20 fw-5">Summary</h4>
          </div>

          <div class="d-flex justify-content-end col-md-6">
            <div class="text-end show dropdown">
              <button 
                @click="openDrop()"
                type="button"
                class="dropdown-toggle btn btn-primary"
              >
                Options
              </button>
              <div v-if="isHide" class="dropdown-menu show dropdown-menu-end">
                <a class="dropdown-item"  @click="edituser">Edit User</a>
                <a class="dropdown-item" @click="deleteUserDetails">Remove User</a>
  <!--               <hr class="dropdown-divider" />
                <a class="dropdown-item" href="#">Add App Instances</a -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white d-flex main-box">
      <div class="left-tabs">
        <ul>
          <li
            :class="{ active: activeTab === 1 }"
            @click="selectConnectedAppdata(1)"
          >
            User Information
          </li>
<!--           <li
            :class="{ active: activeTab === 2 }"
            @click="selectConnectedAppdata(2)"
          >
            User List
          </li> -->
        </ul>
      </div>
      <div class="right-tabs vertical-scroll">
        <UserInfo v-if="selectedTab == 1" :id="id" />
        <!-- <UserTable v-if="selectedTab == 2" :id="id" /> -->
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import UserInfo from "../UserSummary/UserInformation.vue";
// import UserTable from "../EntComponents/UserTable.vue";
import { toast } from 'vue3-toastify';
export default {
  props: {
    id: Number,
    default:0,
  },
  name: "UserSummary",
  components: {
    UserInfo,
  },
  data() {
    return {
      selectedTab: 1,
      activeTab: 1,
      isHide: false,
    };
  },

  methods: {

    selectConnectedAppdata(val) {
      this.selectedTab = val;
      this.activeTab = val;
    },
    hide() {
      this.isHide = false;
    },
    openDrop() {
      if(this.isHide == true){
        this.isHide = false;
      }else{  
        this.isHide = true;
      }
    },
    back(){
      window.history.back();
    },
    edituser(event) {
      let id = this.id;
      this.$router.push({ name: 'EditUser', params: { id:id }});
    },
      // 106:       

    deleteUserDetails() {

      let self = this;
      self.$loading = true;
        axios.post("api/v1/vendor/deleteuser/"+this.id)
        .then(function (response) {
          toast.success(response.data.message);
          window.history.back();
        })
        .catch(function (error) {
          self.$loading = false;
          toast.error("something went wrong");
        });
    },

  },
};
</script>
<style>
@import url("../../../assets/styles/themes/_default.scss");
</style>
